<template>
	<div class="tab-text">

		<div class="vx-row mb-12">
			<div v-bind:class="[
				detail ? detailShow + ' vertical-divider' : '',
				detailHide,
			]">
				<!-- <vs-button class="mb-2" v-on:click="downloadExcel">Export Excel</vs-button> -->
				<data-table :responseData="responseData" :propsParams="params" :header="header"
					@reloadDataFromChild="reloadData">
					<template slot="tbody">
						<vs-tr :key="indextr" v-for="(tr, indextr) in data">
							<vs-td>
								{{ tr.code }}
							</vs-td>
							<vs-td>
								{{ tr.count }}
							</vs-td>
						</vs-tr>
					</template>
				</data-table>
			</div>
			<!-- <transition name="detail-fade">
				<div v-if="detail" v-bind:class="[
						detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
						detailHide,
					]">
					<div>
						<vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth"
							vs-w="4">
							<vs-button size="small" v-on:click="closeDetail" color="grey" icon-pack="feather"
								icon="icon-x-square">Close</vs-button>
						</vs-col>

						<detail :data-id="dataId" :selected="selectedData"></detail>
					</div>
				</div>
			</transition> -->
		</div>
	</div>
</template>

<script>
import detail from "./detail.vue";
import moment from 'moment'

export default {
	components: {
		detail,
	},
	props: [
		"operatingUnit",
		"journalType",
		"postingDate"
	],
	data() {
		return {
			params: {
				search: "",
				length: 10,
				page: 1,
				operatingUnitId: null,
			},
			header: [
				{
					text: "Code",
					value: "code",
					width: "50%"
				},
				// {
				// 	text: "Name",
				// 	value: "name",
				// },
				// {
				// 	text: "Transaction",
				// 	value: "transaction",
				// },
				// {
				// 	text: "Total Internal",
				// 	value: "count",
				// },
                // {
				// 	text: "Total Transaction",
				// 	value: "count_transaction",
				// },
				{
					text: "Total Pending",
					value: "count",
					width: "50%"
				},
			],
			data: [],
			responseData: {},
			isActive: 1,
			partnerBaseUrl: "",
			detailShow: "vx-col md:w-1/2 w-full mb-base",
			detailHide: "core vx-col md:w-1/1 w-full mb-base",
			detail: false,
			selectedData: {},
			dataId: 0,
		};
	},
    mounted() {
        console.log(this.responseData);
    },
	watch: {
		"operatingUnit.selected": function (val) {
			console.log("watch", val)
			this.params = {
				search: "",
				length: 10,
				page: 1,
			}
			this.reloadData(this.params);
		},
		"journalType.selected": function (val) {
			console.log("watch", val)
			this.params = {
				search: "",
				length: 10,
				page: 1,
			}
			this.reloadData(this.params);
		},
		// "postingDate.startDate":function (val) {
		// 	this.params = {
		// 		search: "",
		// 		length: 10,
		// 		page: 1,
		// 	}
		// 	this.reloadData(this.params);
		// }, 
		"postingDate.endDate":function () {
			this.params = {
				search: "",
				length: 10,
				page: 1,
			}
			this.reloadData(this.params);
		} 
	},
	methods: {
		filterOperatingUnit(territoryID) {
			var result = this.operatingUnit.options.filter(v => {
				return v.OperatingUnitTerritory.TerritoryID == territoryID
			})
			console.log(result)
			return "(" + result[0].Code + ") " + result[0].OperatingUnitTerritory.Territory.code + " " + result[0].OperatingUnitTerritory.Territory.name
		},
		clickTr(val) {
			this.closeDetail();
			setTimeout(function () { }, 3000);
			this.dataId = val.id;
			this.selectedData = val;
			this.detail = true;
		},
		closeDetail() {
			this.dataId = 0;
			this.detail = false;
		},
		reloadData(params) {
			console.log("reload data")
			this.params = params;
			this.$vs.loading();
			this.$http
				.get("api/v1/report/monitoring-journal/journal-pending", {
					params: {
						search: this.params.search,
						length: this.params.length,
						page: this.params.page,
						start_posting_date: this.postingDate.startDate != null ? moment(this.postingDate.startDate).format("YYYY-MM-DD") : null,
						end_posting_date: this.postingDate.endDate != null ? moment(this.postingDate.endDate).format("YYYY-MM-DD") : null,
						// tab: "not-generate-journal"
					},
				})
				.then((resp) => {
					if (resp.code == 500) {
						this.$vs.loading.close();
					} else if (resp.code == 200) {
						this.$vs.loading.close();
						// console.log(resp.data);
						this.data = resp.data.records;
						this.responseData = resp.data;
						this.recordsTotal = resp.data.recordsTotal;
					} else {
						this.$vs.loading.close();
					}
				});
		},
		dateFormat(date) {
			if (date) {
				let dt = new Date(date)
				let d = dt.getDate()
				let m = dt.getMonth() + 1
				let y = dt.getFullYear()
				return d + "/" + m + "/" + y
			}
		},
	},
};
</script>
<style scoped>
.nonfixed {
	position: inherit;
	padding-left: 20px;
}

.core-enter-active {
	transition: all 0.3s ease;
}

.core-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
	{
	transform: translateX(10px);
	opacity: 0;
}

.core {
	-webkit-transform-origin-y: all 1s ease;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
	font-size: 12px;
}

.vertical-divider {
	border-right: 1px solid #7367f0;
	/* min-height: 800px; */
	/* height: 100%; */
	-webkit-mask-position-y: fixed;
	/* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
	background: beige;
}

.colored {
	border: 1px solid #7367f0;
	position: fixed;
	left: 4%;
	top: 40%;
	max-width: 50%;
	z-index: 999999999999;
	background: antiquewhite;
	background-color: antiquewhite;
}

.red {
	background: red;
	background-color: red;
}

.yellow {
	background: yellow;
	background-color: yellow;
}
</style>