var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-text"},[_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"tbody"},_vm._l((_vm.data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',[_vm._v(" "+_vm._s(tr.transaction)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.doc_number)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.journal_type)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.operating_unit)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.dateFormat(tr.posting_date))+" ")])],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),_c('detail',{attrs:{"data-id":_vm.dataId,"selected":_vm.selectedData}})],1)]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }