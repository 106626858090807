<template>
  <div>
    <data-table :responseData="responseData" :propsParams="params" :header="header"
      @reloadDataFromChild="reloadData">
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <vs-button
              size="[detail ? 'small' : '', '']"
              v-on:click="confirm(tr)"
              color="primary"
              type="gradient"
              icon-pack="feather"
              icon="icon-search"
            >{{ tr.code }}</vs-button>
          </vs-td>
          <vs-td>
            {{ tr.ref_code }}
          </vs-td>
          <vs-td>
            {{ tr.source_transaction }}
          </vs-td>
          <vs-td>
            {{ tr.operating_unit }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>

    <div>
			<vs-popup
				color="primary"
				title="Journal Details"
				@cancel="closeConfirm"
				@close="closeConfirm"
        :buttons-hidden="true"
				:active.sync="activePromptConfrm"
			>
        <template :style="{width: '1000px'}">
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <strong>Code External :</strong>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <strong>Code Internal :</strong>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              {{ selectedData.code_external }}
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              {{ selectedData.code }}
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <strong>Ref Code External :</strong>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <strong>Ref Code Internal :</strong>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              {{ selectedData.ref_code_external }}
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              {{ selectedData.ref_code }}
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <strong>Source Trans External :</strong>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <strong>Source Trans Internal :</strong>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              {{ selectedData.source_transaction_external }}
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              {{ selectedData.source_transaction }}
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <strong>Operating Unit External :</strong>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <strong>Operating Unit Internal :</strong>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              {{ selectedData.operating_unit_external }}
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              {{ selectedData.operating_unit }}
            </vs-col>
          </vs-row>
        </template>
			</vs-popup>
		</div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  components: {},
  props: {
    selectedCode:String,
    selected: {},
    postingDate: [],
  },
  watch: {
    selected() {
      this.reloadData(this.params);
      // console.log("ini jalan: selected");
    },
  },
  mounted() {
    // console.log(this.selected.code, "selected");
    // this.reloadData();
  },
  data: () => ({
    params: {
      search: "",
      length: 100,
      page: 1,
    },
    header: [
      {
        text: "Code",
        value: "code",
        // width: '5%'
      },
      {
        text: "Ref Code",
        value: "ref_code",
        // width: '5%'
      },
      {
        text: "Source Trans",
        value: "source_transaction",
        // width: '5%'
      },
      {
        text: "Operating Unit",
        value: "operating_unit",
        //width: '15%'
      },
    ],
    responseData: [],
    data: [],
    selectedData: {},
    activePromptConfrm: false,
  }),
  methods: {
    confirm(tr){
      this.activePromptConfrm = true
      this.selectedData = tr;
      console.log("Confirm", tr);
    },
    closeConfirm() {
      this.activePromptConfrm = false
      this.selectedData = {};
    },
    dateFormat(date){
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/report/monitoring-journal/compare/detail", {
          params: {
            search: this.params.search,
						length: this.params.length,
						page: this.params.page,
            code: this.selected.code,
            start_posting_date: this.postingDate.startDate != null ? moment(this.postingDate.startDate).format("YYYY-MM-DD") : null,
						end_posting_date: this.postingDate.endDate != null ? moment(this.postingDate.endDate).format("YYYY-MM-DD") : null,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            console.log(resp.data, "data")
            this.responseData = resp.data;
            this.data = resp.data.records
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
.margin-btm {
  margin-bottom: 1rem !important;
}
.vs-input--input.normal {
  text-align: end;
}

.vs-dialog {
  max-width: 1000px;
}
</style>